import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import questionsList from "./modules/questions";
import users from "./modules/users"


Vue.use(Vuex);

export const localStorageKey = 'cpa-kakomon';

export default new Vuex.Store({
  modules: {
    questionsList,
    users,
  },
  plugins: [createPersistedState(
      {
        key: localStorageKey,
        path: ['users', 'questions'],
        storage: window.localStorage
      }
  )],
})
