<template>
  <v-app>
    <app-header />
    <v-main>
      <v-row class="justify-space-around mx-0">
        <v-sheet
          class="col-11 mt-5"
          :elevation="1"
          max-width="800px"
        >
          <transition name="slide">
            <router-view />
          </transition>
        </v-sheet>
      </v-row>
      <v-snackbar
        v-model="successBar"
        bottom="bottom"
        color="success"
      >
        ログインしました
        <template v-slot:action="{ attrs }">
          <v-icon
            v-bind="attrs"
            dark="dark"
            @click="successBar = false"
          >
            mdi-close
          </v-icon>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="apiFailed"
        bottom="bottom"
        color="error"
      >
        データの取得に失敗しました、再度お試しください
        <template v-slot:action="{ attrs }">
          <v-icon
            v-bind="attrs"
            dark="dark"
            @click="apiFailed = false"
          >
            mdi-close
          </v-icon>
        </template>
      </v-snackbar>
    </v-main>
    <app-footer />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

export default {
  name: "BaseLayout",
  components: { AppHeader, AppFooter },
  data() {
    return {
      successBar: false,
      apiFailed: false,
    }
  },
  computed: {
    ...mapGetters(['isLogin', 'responseStatus', 'expiredAt', 'autoLogoutFlg']),
  },
  watch: {
    isLogin() {
      if (this.isLogin) this.successBar = true;
    },
    apiFailed() {
      return !this.responseStatus
    }
  },
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>
