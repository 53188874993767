import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router'
import { sync } from 'vuex-router-sync'
import authConfig from "../auth_config.json";
import { Auth0Plugin } from "./auth";
import VueGtm from "vue-gtm"
import './registerServiceWorker'

const gtmContainerId = 'GTM-MS7HPTV'
Vue.use(VueGtm, {
      id: gtmContainerId,
      defer: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router,
      trackOnNextTick: false,
    }
);

const { domain, clientId, ui_locales } = authConfig["kakomon-production"]

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  ui_locales,
  useRefreshTokens: true,
  onRedirectCallback: appState => {
    router.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
  }
});

Vue.config.productionTip = false;
sync(store, router);


new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

