<template>
  <div>
    <base-layout />
  </div>
</template>

<script>
import BaseLayout from "./components/layout/BaseLayout";
import axios from "axios";

export default {
  components: { BaseLayout },
  computed: {
    isAuthenticated() {
      return this.$auth.isAuthenticated;
    }
  },
  watch: {
    isAuthenticated() {
      this.$store.dispatch("setAuth", this.$auth);
    }
  },
  mounted() {
    axios.get("/api/v1/ping");
    setInterval(() => {
      const exp = this.$store.state.users?.accessToken?.exp;
      const tokenExpired = exp < Date.now() / 1000;
      if (tokenExpired) {
        this.$auth
          .getTokenSilently()
          .then(() => {
            this.$store.dispatch("setAuth", this.$auth);
          })
          .catch(() => {
            // this.$auth.logout()
          });
      }
    }, 1000 * 60 * 5);
  }
};
</script>
