import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'top',
    component: () => import("../pages/Top")
  },
  {
    path: '/select_questions/:mode',
    component: () => import('../pages/SelectQuestions'),
    props: true,
  },
  {
    path: '/select_questions',
    component: () => import('../pages/SelectQuestions'),
  },
  {
    path: '/question/:page',
    name: 'question',
    component: () => import('../pages/Question'),
  },
  {
    path: '/sample_question',
    name: 'SampleQuestion',
    component: () => import('../components/Top/SampleQuestion'),
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../components/Question/Result')
  },
  {
    path: '/past_results',
    name: 'past_results',
    component: () => import('../pages/PastResult'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../pages/Account'),
    beforeEnter: authGuard,
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('../pages/Terms'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('../pages/PrivacyPolicy'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../pages/Contact'),
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('../pages/Statistics'),
  },
  {
    path: '/newscpa',
    name: 'newscpa',
    component: () => import('../pages/Newscpa'),
  },
  {
    path: '/news-bdo',
    component: () => import('../pages/NewsBdo'),
  },
  {
    path: '/news-esnet',
    component: () => import('../pages/NewsEsnet'),
  },
  {
    path: '/news-growth',
    component: () => import('../pages/news/NewsGrowth'),
  },
  {
    path: '/login',
    component: () => import('../pages/Top'),
  },
  {
    path: '*',
    redirect: { name: 'top' }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
