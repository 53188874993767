<template>
  <v-footer
    app="app"
    absolute="absolute"
    color="primary"
    dark="dark"
    padless="padless"
  >
    <v-row class="no-gutters justify-center">
      <v-btn
        color="white"
        text="text"
        :to="'/terms'"
      >
        利用規約
      </v-btn>
      <v-btn
        color="white"
        text="text"
        :to="'/privacy-policy'"
      >
        プライバシーポリシー
      </v-btn>
      <v-btn
        color="white"
        text="text"
        :to="'/contact'"
      >
        お問い合わせ
      </v-btn>
    </v-row>
    <v-col class="caption text-center col-12">
      &copy; 2020 公認会計士試験過去問コム All Rights Reserved.
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    absolute: () => false,
  }
}
</script>

<style scoped>

</style>
