<template>
  <v-app-bar
    app="app"
    color="primary"
    dark="dark"
    clipped-left="clipped-left"
  >
    <router-link to="/">
      <v-img
        src="@/assets/CPA過去問.png"
        contain="contain"
        max-width="130px"
      />
    </router-link>
    <v-spacer />
    <v-toolbar-items>
      <!-- マニュアルへのリンク-->
      <template v-if="manualLink">
        <v-btn
          class="d-none d-sm-flex"
          text="text"
          :href="manualLink"
          target="_blank"
        >
          マニュアル
        </v-btn>
        <v-btn
          class="d-sm-none"
          text="text"
          :href="manualLink"
          target="_blank"
        >
          <v-icon>mdi-book-open-variant</v-icon>
        </v-btn>
      </template>
      <!-- 問題選択画面へのリンク-->
      <v-btn
        v-if="questions.length === 0"
        class="d-none d-sm-flex"
        text="text"
        to="/select_questions"
      >
        問題開始
      </v-btn>
      <v-btn
        v-if="questions.length === 0"
        class="d-sm-none"
        text="text"
        to="/select_questions"
      >
        <v-icon>mdi-school</v-icon>
      </v-btn>
      <!-- 解いている問題にも戻る: 一問一答問題以外にも対応する-->
      <v-btn
        v-if="questions.length !== 0"
        class="d-none d-sm-flex"
        text="text"
        :to="lastQuestionLink"
      >
        問題に戻る
      </v-btn>
      <v-btn
        v-if="questions.length !== 0"
        class="d-sm-none"
        text="text"
        to="/question/1"
      >
        <v-icon>mdi-playlist-play</v-icon>
      </v-btn>
      <!-- ログイン関連ボタンログインしていない場合
    -->
      <v-btn
        v-if="!isLogin &amp;&amp; !$auth.loading"
        class="d-none d-sm-flex"
        text="text"
        @click="login()"
      >
        ログイン
      </v-btn>
      <v-btn
        v-if="!isLogin &amp;&amp; !$auth.loading"
        class="d-sm-none"
        text="text"
        @click="login()"
      >
        <v-icon>mdi-login</v-icon>
      </v-btn>
      <!-- ログインしている場合-->
      <template v-if="isLogin &amp;&amp; !$auth.loading">
        <v-menu
          offset-y="offset-y"
          absolute="absolute"
        >
          <template v-slot:activator="{on}">
            <v-btn
              class="d-none d-sm-flex"
              text="text"
              v-on="on"
            >
              {{ userName }}
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
            <v-btn
              class="d-sm-none"
              text="text"
              v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-subheader>アカウント情報</v-subheader>
            <v-list-item
              v-for="support in supports"
              :key="support.name"
              :to="support.link"
              @click="support.name === 'ログアウト' ? logout() : ''"
            >
              <v-list-item-icon>
                <v-icon>{{ support.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ support.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "AppHeader",
  data() {
    return {
      openWhatsNew: false
    }
  },
  computed: {
    ...mapGetters(['userName', 'isLogin', 'questions', 'lastQuestionPage']),
    lastQuestionLink() {
      return `/question/${this.lastQuestionPage}`;
    },
    isAuthenticated() {
      return this.$auth.isAuthenticated
    },
    imageSrc() {
      return require('@/assets/CPA過去問.png')
    },
    supports() {
      return [
        {
          name: 'アカウント設定',
          icon: 'mdi-account',
          link: '/account'
        },
        {
          name: '直近の成績確認',
          icon: 'mdi-ballot',
          link: '/past_results'
        },
        {
          name: 'ログアウト',
          icon: 'mdi-logout',
        },
      ];
    },
    manualLink() {
      return process.env.VUE_APP_MANUAL_URL
    }
  },
  methods: {
    async login() {
      await this.$auth.loginWithRedirect();
    },
    logout() {
      this.$store.dispatch('logout', this.$auth)
    },
    jumpQuestionPage() {
      this.$router.push({ name: 'question', params: { page: this.$store.state.route.params.page } });
    },
  },
}
</script>

<style scoped>

</style>
