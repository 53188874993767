import axios from 'axios';

const state = {
  questions: {},
  selectedConditions: {
    exam: [],
    category: [],
    importance: ['A', 'B', 'C'],
    random: true,
    limit: 40,
    wrong: false,
    bookmark: false,
    unsolved: false,
    wrong_count: null,
  },
  responseStatus: true,
  laterCheckMode: false,
  hideAnswerMode: false,
  finished: false,
  lastQuestionPage: null
};

const mutations = {
  UPDATE_QUESTIONS(state, payload) {
    state.questions = payload.reduce((result, current, index) => {
      if (current.flg === undefined) {
        current.flg = false
      }
      current.last_answer = null;
      current.laterCheck = false;
      result[index + 1] = current;
      return result;
    }, {})
  },
  RESET_QUESTIONS(state) {
    state.questions = {};
  },
  UPDATE_SELECTED_CONDITIONS(state, payload) {
    if (payload.exam) state.selectedConditions.exam = payload.exam;
    if (payload.category) state.selectedConditions.category = payload.category;
    if (payload.importance) state.selectedConditions.importance = payload.importance;
    if (payload.limit) state.selectedConditions.limit = payload.limit;
    if (payload.random !== undefined) state.selectedConditions.random = payload.random;
    if (payload.wrong !== undefined) state.selectedConditions.wrong = payload.wrong;
    if (payload.unsolved !== undefined) state.selectedConditions.unsolved = payload.unsolved;
    if (payload.bookmark !== undefined) state.selectedConditions.bookmark = payload.bookmark;
    if (payload.wrong_count !== undefined) state.selectedConditions.wrong_count = payload.wrong_count;
  },
  ANSWER_CORRECT(state, page) {
    state.questions[page].correct = true;
  },
  ANSWER_WRONG(state, page) {
    state.questions[page].correct = false;
  },
  TOGGLE_QUESTION_FLG(state, page) {
    state.questions[page].flg = !state.questions[page].flg;
  },
  TOGGLE_LATER_CHECK_FLG(state, page) {
    state.questions[page].laterCheck = !state.questions[page].laterCheck;
  },
  ENABLE_LATER_CHECK_MODE(state) {
    state.laterCheckMode = true
  },
  DISABLE_LATER_CHECK_MODE(state) {
    state.laterCheckMode = false
  },
  UPDATE_API_RESPONSE_STATUS(state, status) {
    state.responseStatus = status
  },
  UPDATE_HIDE_ANSWER_MODE(state, flg) {
    state.hideAnswerMode = flg
  },
  UPDATE_FINISHED(state, flg) {
    state.finished = flg
  },
  UPDATE_LAST_QUESTION_PAGE(state, page) {
    state.lastQuestionPage = Number(page) || 1
  },
  UPDATE_QUESTION_MEMO(state, { page, memo }) {
    state.questions[page].memo = memo
  },
};

const actions = {
  async getQuestions({ commit, state }) {
    const url = '/api/v1/single_questions';
    const params = { ...state.selectedConditions }
    delete params.importance; // TODO: CPAモードの名残なので削除する
    await axios.post(url, params)
        .then((res) => {
          commit('UPDATE_QUESTIONS', res.data);
          commit('UPDATE_API_RESPONSE_STATUS', true);
        }).catch(() => {
          commit('UPDATE_API_RESPONSE_STATUS', false);
        });
  },
  updateSelectedConditions({ commit }, conditions) {
    commit('UPDATE_SELECTED_CONDITIONS', conditions);
    commit('UPDATE_FINISHED', false);
  },
  answerCorrect({ commit, rootState }) {
    commit('ANSWER_CORRECT', rootState.route.params.page)
  },
  answerWrong({ commit, rootState }) {
    commit('ANSWER_WRONG', rootState.route.params.page)
  },
  toggleQuestionFlg({ commit, getters }) {
    commit('TOGGLE_QUESTION_FLG', getters.currentPage);
    if (!getters.isLogin) return true;

    const question = getters.getQuestionByPage;
    const param = { questions: [{ id: question.id, flg: question.flg }] };
    axios.post('api/v1/users/questions', param).then(() => {
      commit('UPDATE_API_RESPONSE_STATUS', true);
    }).catch(() => {
      commit('UPDATE_API_RESPONSE_STATUS', false);
    });
  },
  toggleLaterCheck({ commit, rootState }) {
    commit('TOGGLE_LATER_CHECK_FLG', rootState.route.params.page)
  },
  enableLaterCheckMode({ commit }) {
    commit('ENABLE_LATER_CHECK_MODE')
  },
  disableLaterCheckMode({ commit }) {
    commit('DISABLE_LATER_CHECK_MODE')
  },
  updateHideAnswerMode({ commit }, flg) {
    commit('UPDATE_HIDE_ANSWER_MODE', flg);
  },
  updateFinished({ commit }, flg) {
    commit('UPDATE_FINISHED', flg);
  },
  updateLastQuestionPage({ commit }, page) {
    commit('UPDATE_LAST_QUESTION_PAGE', page);
  },
  async sendResult({ commit, state, getters }) {
    commit('UPDATE_FINISHED', false);
    if (!getters.isLogin) return true;

    const questions = Object.values(state.questions).map((q) => {
      return { id: q.id, flg: q.flg, correct: q.correct };
    }).filter(e => e.correct !== '');
    const param = { questions: questions };
    await axios.post('api/v1/users/questions', param).then(() => {
      commit('UPDATE_API_RESPONSE_STATUS', true);
    }).catch(() => {
      commit('UPDATE_API_RESPONSE_STATUS', false);
    });
  },
  async updateQuestionMemo({ commit, getters }, memo) {
    const page = getters.currentPage;
    const question = getters.getQuestionByPage;
    const param = { question_id: question.id, memo: memo };
    await axios.post('api/v1/users/memo', param).then(() => {
      commit('UPDATE_API_RESPONSE_STATUS', true);
    }).catch(() => {
      commit('UPDATE_API_RESPONSE_STATUS', false);
    });
    commit('UPDATE_QUESTION_MEMO', { page, memo });
  }
};

const getters = {
  questions: state => Object.values(state.questions),
  lastPageNum: (state, getters) => getters.questions.length,
  getQuestionByPage: (state, getters, rootState) => state.questions[rootState.route.params.page],
  isLastQuestion: (state, getters) => getters.questions.length === getters.currentPage,
  limit: state => state.selectedConditions.limit,
  random: state => state.selectedConditions.random,
  wrong: state => state.selectedConditions.wrong,
  unsolved: state => state.selectedConditions.unsolved,
  bookmark: state => state.selectedConditions.bookmark,
  wrong_count: state => state.selectedConditions.wrong_count,
  importance: state => state.selectedConditions.importance,
  hideAnswerMode: state => state.hideAnswerMode,
  currentPage: (state, getters, rootState) => parseInt(rootState.route.params.page),
  questionSolved: (state, getters) => getters.questions.filter(q => q.correct !== '').length,
  questionCorrected: (state, getters) => getters.questions.filter((q) => q.correct).length,
  responseStatus: state => state.responseStatus,
  finished: state => state.finished,
  lastQuestionPage: state => state.lastQuestionPage,
  checkLaterPages: (state, getters) => getters.questions.filter((q) => q.laterCheck).map((q) => q.page),
};

const questionsListModule = {
  state,
  mutations,
  actions,
  getters
};

export default questionsListModule;
