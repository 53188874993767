// import axios from 'axios';

const default_state = {
  accessToken: null,
  idToken: null,
  expiredAt: null,
  isLogin: false,
  userName: null,
  uid: null,
  email: null,
  user: null,
  autoLogoutFlg: true,
}


const state = default_state

const mutations = {
  UPDATE_ID_TOKEN(state, idToken) {
    state.idToken = idToken
  },
  UPDATE_ACCESS_TOKEN(state, accessToken) {
    state.accessToken = accessToken
  },
  UPDATE_USER(state, user) {
    state.user = user;
    state.userName = user.name;
    state.uid = user.sub;
    state.email = user.email;
    state.isLogin = true;
  },
  RESET_AUTH(state) {
    Object.assign(state, default_state)
  },
};

const actions = {
  async setAuth({ commit }, auth) {
    const idToken = await auth.getIdTokenClaims();
    commit('UPDATE_ID_TOKEN', idToken.__raw);
    commit('UPDATE_ACCESS_TOKEN', idToken);
    commit('UPDATE_USER', auth.user);
  },
  logout({ commit }, auth) {
    auth.logout({ returnTo: window.location.origin });
    commit('RESET_AUTH');
  }
};

const getters = {
  userName: state => state.userName,
  email: state => state.email,
  errorMessages: state => state.errorMessages,
  isLogin: state => state.isLogin,
  autoLogoutFlg: state => state.autoLogoutFlg,
  expireDurationMinutes: state => state.expireDurationMinutes,
};

const usersModule = {
  state,
  mutations,
  actions,
  getters
};

export default usersModule;
